import { Burger } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconBrandWhatsapp } from "@tabler/icons-react";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ModalPop from "../../home/ModalPop";

const MHeader = () => {
  const navigate = useNavigate();

  const [opened, { toggle }] = useDisclosure(false);

  const phoneMenuListRef = useRef<HTMLDivElement>(null);
  const burgerIconRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    burgerIconRef.current?.addEventListener("click", () => {
      opened
        ? (phoneMenuListRef.current!.style.transform = "translate(0, -120%)")
        : (phoneMenuListRef.current!.style.transform = "translate(0, 0)");
    });
  }, [opened]);

  const menuOnclick = (link: string) => {
    let menuHeight =
      document.querySelector<HTMLDivElement>("#header")!.offsetHeight;

    var location = document.querySelector<HTMLDivElement>(link)!.offsetTop;

    window.scrollY &&
      window.scrollTo({
        top: location - menuHeight,
        behavior: "smooth",
      });
  };

  const phoneMenuOnclick = (link: string) => {
    let menuHeight =
      document.querySelector<HTMLDivElement>("#header")!.offsetHeight;

    var location = document.querySelector<HTMLDivElement>(link)!.offsetTop;

    window.scrollY &&
      window.scrollTo({
        top: location - menuHeight,
        behavior: "smooth",
      });

    toggle();
    phoneMenuListRef.current!.style.transform = "translate(0, -100%)";
  };

  const popupRef = useRef<HTMLDivElement>(null);

  const openfunc = () => {
    popupRef.current!.style.display = "block";
  };

  const closefunc = () => {
    popupRef.current!.style.display = "none";
  };
  return (
    <header className="fixed left-0 top-0 w-full h-fit max-w-[100vw] bg-white z-40">
      <div
        id="header"
        className="relative w-full h-fit px-[calc((100%-var(--container))/2)] py-2 max-h-20 lg:max-h-32 overflow-hidden z-40 transition-all duration-300"
      >
        <div className="relative w-full h-full mx-auto flex justify-between items-center gap-6 xl:gap-16">
          <Burger
            ref={burgerIconRef}
            opened={opened}
            onClick={toggle}
            className="block lg:hidden"
            size="md"
            color="#29a7ee"
          />
          <div className="relative w-full h-fit max-w-52 xl:max-w-64">
            <img
              alt="logo"
              onClick={() => navigate("/")}
              className="!relative w-full h-auto max-h-16 xl:max-h-20 py-3 object-contain cursor-pointer"
              src="/images/logo.png"
              width={429}
              height={153}
            />
          </div>
          <div className="block lg:hidden relative w-fit h-fit p-1 mx-1 rounded-full">
            <IconBrandWhatsapp
              onClick={() => {
                // @ts-ignore
                AM_PL("https://xn--od1bk6qg0eba454b.com/tel");
                window.location.href = "tel:010-8330-8913";
              }}
              color="#29a7ee"
              size={30}
              stroke={1}
              className="text-main"
            />
          </div>
          <div className="hidden lg:block relative w-full h-fit max-w-screen-sm xl:max-w-screen-md 3xl:max-w-screen-lg">
            <img
              onClick={() => {
                // @ts-ignore
                AM_PL("https://xn--od1bk6qg0eba454b.com/tel");
                window.location.href = "tel:010-8330-8913";
              }}
              className="!relative w-full h-auto max-h-20 lg:max-h-36 py-3 object-contain cursor-pointer"
              alt="button"
              src="/images/number.png"
              width={532}
              height={132}
            />
          </div>
        </div>
      </div>
      <div
        ref={phoneMenuListRef}
        className="block lg:hidden fixed top-16 left-0 w-full h-full -translate-y-full transition-transform duration-500 z-30"
      >
        <ul className="border-b bg-white text-font">
          <li onClick={() => phoneMenuOnclick("#about")}>
            <p className="border-b py-4 text-center">누수방수야</p>
          </li>
          <li onClick={() => phoneMenuOnclick("#service")}>
            <p className="border-b py-4 text-center">서비스&nbsp;목록</p>
          </li>
          <li onClick={() => phoneMenuOnclick("#blog")}>
            <p className="border-b py-4 text-center">작업사례</p>
          </li>
          <li onClick={openfunc}>
            <p className="border-b py-4 text-center flex justify-center items-center">
              빠른
              <img
                src="/images/icon_star.png"
                alt="icon"
                width={15}
                height={15}
              />
              견적문의
            </p>
          </li>
          <li onClick={() => phoneMenuOnclick("#qna")}>
            <p className="border-b py-4 text-center">Q&A</p>
          </li>
          <li onClick={() => phoneMenuOnclick("#equip")}>
            <p className="border-b py-4 text-center">최신장비</p>
          </li>
          <li onClick={() => phoneMenuOnclick("#banner")}>
            <p className="border-b py-4 text-cente flex justify-center items-centerr">
              상세
              <img
                src="/images/icon_star.png"
                alt="icon"
                width={15}
                height={15}
              />
              견적문의
            </p>
          </li>
        </ul>
      </div>
      <div className=" hidden lg:block relative w-full h-full px-[calc((100%-var(--container))/2)] py-2 border-t border-b">
        <ul className="relative w-full h-fit text-center flex justify-around items-center justify-items-center gap-5 xl:gap-10 3xl:gap-14 cursor-pointer">
          <li onClick={() => menuOnclick("#about")}>
            <p className="2xl:text-xl 3xl:text-2xl">누수방수야</p>
          </li>
          <li onClick={() => menuOnclick("#service")}>
            <p className="2xl:text-xl 3xl:text-2xl">서비스&nbsp;목록</p>
          </li>
          <li onClick={() => menuOnclick("#blog")}>
            <p className="2xl:text-xl 3xl:text-2xl">작업사례</p>
          </li>
          <li onClick={openfunc}>
            <p className="2xl:text-xl 3xl:text-2xl flex justify-center items-center">
              빠른
              <img
                src="/images/icon_star.png"
                alt="icon"
                width={15}
                height={15}
              />
              견적문의
            </p>
          </li>
          <li onClick={() => menuOnclick("#qna")}>
            <p className="2xl:text-xl 3xl:text-2xl">Q&A</p>
          </li>
          <li onClick={() => menuOnclick("#equip")}>
            <p className="2xl:text-xl 3xl:text-2xl">최신장비</p>
          </li>
          <li onClick={() => menuOnclick("#banner")}>
            <p className="2xl:text-xl 3xl:text-2xl flex justify-center items-center">
              상세
              <img
                src="/images/icon_star.png"
                alt="icon"
                width={15}
                height={15}
              />
              견적문의
            </p>
          </li>
        </ul>
      </div>
      <ModalPop popupRef={popupRef} closefnc={closefunc} />
    </header>
  );
};

export default MHeader;
